
import { setSettings } from '@!/api/Account/settings'
import { OpenAsinDetailTypeEnum, OpenAsinDetailTypeEnumArr } from '@!/const/AsinDetail'
import { useUserStore } from '../index'
/*

  switchToDrawerTypeInfo -> {
    // 原始保存的路由
    originRoute: object,
    // 打开drawer的参数
    openQuery: object,
    // 打开 drawer 的 tab项
    openTab: string,
    // 打开 drawer的 props
    openProps: object
  }

*/

/**
 * @description: 根据 openType 文本值 获取 索引值
 */
const getOpenAsinDetailTypeIndexValue = (openType = '') => {
  return OpenAsinDetailTypeEnumArr.findIndex(item => { return item === openType })
}

const state = () => {
  return {
    // 打开 AsinDetail 方式
    openAsinDetailType: OpenAsinDetailTypeEnum.Drawer,
    // 从 Tab 方式切换回 Drawer 方式保存的信息
    switchToDrawerTypeInfo: ''
  }
}

const actions = {
  updateAsinDetailTypeAndUserSetting(openType = '') {
    const userStore = useUserStore()
    this.openAsinDetailType = openType
    userStore.SET_USERSETTINGS({
      ...userStore.userSettings,
      asinStatusDetail: getOpenAsinDetailTypeIndexValue(openType)
    })
  },
  // 设置 打开 AsinDetail 方式
  setOpenAsinDetailType(openType = this.openAsinDetailType, pushOrigin = true) {
    // pushOrigin 是否同步远程
    if (pushOrigin) {
      return setSettings({
        asinStatusDetail: OpenAsinDetailTypeEnumArr.indexOf(openType)
      }).then(() => {
        this.updateAsinDetailTypeAndUserSetting(openType)
        return Promise.resolve(this.openAsinDetailType)
      }).catch(err => {
        console.log('AsinDetail openType push Error: ', err)
        return Promise.reject(err)
      })
    } else {
      this.updateAsinDetailTypeAndUserSetting(openType)
      return Promise.resolve(this.openAsinDetailType)
    }
  },
  // 根据元组枚举设置 打开 AsinDetail 方式
  setOpenAsinDetailTypeByArrEnum(arrEnumOpenType = 0, pushOrigin = true) {
    const openType = OpenAsinDetailTypeEnumArr[ arrEnumOpenType ]
    this.setOpenAsinDetailType(openType, pushOrigin)
  },
  // 设置 从 Tab 方式切换回 Drawer 方式保存的信息
  setSwitchToDrawerTypeInfo(info = '') {
    this.switchToDrawerTypeInfo = info
  },
  clearAsinDetailStore() {
    this.setOpenAsinDetailType(this.openAsinDetailType, false)
    this.setSwitchToDrawerTypeInfo()
  }
}

export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isModuleStorage: true,
    isLogoutClearStorage: false,
    watchFields: ['openAsinDetailType', 'switchToDrawerTypeInfo']
  }
}
