import Main from "@pacvue/frame/layout/Main.vue"
import { getUserGuidanceStep } from "@customDashboard/api/index.js"

const VITE_APP_NODE_ENV = import.meta.env.VITE_APP_NODE_ENV
const VITE_APP_APIENV = import.meta.env.VITE_APP_APIENV
const customDashboard_NODE_ENV = VITE_APP_NODE_ENV + "_" + VITE_APP_APIENV
const productline = localStorage.getItem("retailer_navSrcPlt")
export default {
  router: {
    path: "/Report/CustomDashboard",
    component: Main,
    meta: { auth: true, id: -1 },
    beforeEnter: async () => {
      const uid = localStorage.getItem("uid")
      if (uid) {
        try {
          if (productline == "walmart" && customDashboard_NODE_ENV === "production_eu") {
            localStorage.setItem(`customDashboard_${uid}_SettingStore`, "")
          } else if (productline == "instacart" && (customDashboard_NODE_ENV === "production_eu" || customDashboard_NODE_ENV === "production_cn")) {
            localStorage.setItem(`customDashboard_${uid}_SettingStore`, "")
          } else if (productline == "criteo" && customDashboard_NODE_ENV === "production_cn") {
            localStorage.setItem(`customDashboard_${uid}_SettingStore`, "")
          } else if (productline == "target" && (customDashboard_NODE_ENV === "production_eu" || customDashboard_NODE_ENV === "production_cn")) {
            localStorage.setItem(`customDashboard_${uid}_SettingStore`, "")
          } else {
            if (customDashboard_NODE_ENV === "production_us" || customDashboard_NODE_ENV === "production_cn") {
              // 在这里调用你需要执行的函数
              const setting = await getUserGuidanceStep()
              if (setting && setting.includeStore) {
                localStorage.setItem(`customDashboard_${uid}_SettingStore`, setting.includeStore)
              } else {
                localStorage.setItem(`customDashboard_${uid}_SettingStore`, "")
              }
            } else {
              localStorage.setItem(`customDashboard_${uid}_SettingStore`, "")
            }
          }
        } catch (err) {
          console.log(err)
        }
      }
    },
    children: [
      {
        path: "/Report/CustomDashboard",
        component: () => import("@customDashboard/index.vue"),
        name: "Custom Dashboard"
      },
      {
        path: "/Report/CustomDashboard/Create",
        component: () => import("@customDashboard/Dashboard/CreateDashboard.vue"),
        name: "Create Dashboard",
        meta: {
          noSideBar: false,
          i18N: $t("Create Dashboard")
        }
      },
      {
        path: "/Report/CustomDashboard/Edit",
        component: () => import("@customDashboard/Dashboard/CreateDashboard.vue")
      },
      {
        path: "/Report/CustomDashboard/ViewSample",
        component: () => import("@customDashboard/Dashboard/CreateDashboard.vue"),
        meta: {
          i18N: $t("View Sample")
        }
      },
      {
        path: "/Report/CustomDashboard/Detail",
        component: () => import("@customDashboard/Detail/DashboardDetail.vue"),
        meta: {
          isShowTime: true
        }
      },
      {
        path: "/Report/CustomDashboard/TemplateManagement",
        component: () => import("@customDashboard/TemplateManagements/index.vue"),
        meta: {
          isShowTime: false
        }
      },
      {
        path: "/Report/CustomDashboard/TemplateManagement/BulkApplyTemplate",
        component: () => import("@customDashboard/TemplateManagements/bulkCreate.vue"),
        meta: {
          isShowTime: false
        }
      },
      {
        path: "/Share/CustomDashboard",
        component: () => import("@customDashboard/Share/sharePage/index.vue"),
        name: "Custom Dashboard Share",
        meta: {
          isShowTime: true,
          __is_share__: true
        }
      },
      {
        path: "/Share/ShareEmpty",
        component: () => import("@customDashboard/Share/ShareEmpty.vue"),
        name: "Custom Dashboard Share Empty",
        meta: {
          __is_share__: true
        }
      }
    ]
  }
}
