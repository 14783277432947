import { isEmpty } from '@commerceCommon/utils/common.js'
import { getShareConfig } from '@commerceCommon/utils/ts-common'
import { useCommonStore, useShareStore } from '@!/store'
import { downloadCommerceCommon } from '@!/api/download.js'
import { $http1p, $http3p, $httpCommon } from '../http'
import { useUserStore } from '@/store/index.js'

export { GetProductTagSellerDropData, GetProductTagVendorDropData } from '../Catalog/tag'
const { isShareLink, shareId } = getShareConfig()

const { VITE_APP_COUNTRY, VITE_APP_ADMIN_MIDDLE } = import.meta.env

/**
 * 获取Market、Seller下拉列表数据
 * @returns {*}
 */
export function GetMarketSellerDropData(data) {
  return $http3p({
    url: 'accountManage/marketSeller',
    method: 'get',
    isUnique: true,
    params: data
  })
}

/**
 * 获取 Seller Market下拉列表数据
 * @returns {*}
 */
export function GetSellerMarketDropData() {
  return $http3p({
    url: '/accountManage/sellerMarket',
    method: 'get',
    isUnique: true
  })
}

/**
 * 获取Market、Vendor下拉列表数据
 * @returns {*}
 */
export function GetMarketVendorDropData(data) {
  return $http1p({
    url: 'accountManage/market/search',
    method: 'post',
    isUnique: true,
    data
  })
}

/**
 * 获取Brand下拉列表数据
 * @returns {*}
 */
export function GetBrandSellerDropData(data = {}) {
  return $http3p({
    url: 'productCatalog/brandCategory',
    method: 'post',
    data: {
      type: 1,
      ...data
    }
  })
}
export function GetBrandVendorDropData(data = {}) {
  return $http1p({
    url: 'productCatalog/brandCategory',
    method: 'post',
    isUnique: true,
    data: {
      type: 1,
      ...data
    }
  })
}

/**
 * 获取Category下拉列表数据
 * @returns {*}
 */
export function GetCategorySellerDropData(data = {}) {
  return $http3p({
    url: 'productCatalog/getOneCategory',
    method: 'post',
    data
  })
}

export function GetCategoryVendorDropData(data = {}) {
  return $http1p({
    url: 'productCatalog/brandCategory',
    method: 'post',
    isUnique: true,
    data: {
      type: 2,
      ...data
    }
  })
}

/**
 * 获取Brand下拉列表数据
 * @returns {*}
 */
export function GetOriginBrandSellerDropData(data = {}) {
  return $http3p({
    url: 'brandSales/getAmazonBrands',
    method: 'post',
    data: {
      type: 1,
      ...data,
      fromPage: window.location.href
    }
  })
}
export function GetOriginBrandVendorDropData(data = {}) {
  return $http1p({
    url: 'productCatalog/getAmazonBrandList',
    method: 'post',
    isUnique: true,
    requestId: 'GetOriginBrandVendorDropData',
    data: {
      type: 1,
      ...data,
      fromPage: window.location.href
    }
  })
}

/**
 * 获取Category下拉列表数据
 * @returns {*}
 */
export function GetOriginCategorySellerDropData(data = {}) {
  return $http3p({
    url: 'brandSales/getAmazonCategories',
    method: 'post',
    data: {
      ...data,
      fromPage: window.location.href
    }
  })
}

export function GetOriginCategoryVendorDropData(data = {}) {
  return $http1p({
    url: 'productCatalog/getAmazonCategoryList',
    method: 'post',
    isUnique: true,
    requestId: 'GetOriginCategoryVendorDropData',
    data: {
      type: 2,
      ...data,
      fromPage: window.location.href
    }
  })
}

const getSource = () => {
  const commonStore = useCommonStore()
  const shareStore = useShareStore()
  const { shareStates } = shareStore
  const commerceType = isShareLink ? shareStates[shareId].channelType : commonStore.commerceType
  return commerceType === 'Seller' ? 3 : 1
}

/**
 * 获取统一后的Brand下拉列表数据
 * 需要Asin Status筛选的，需要传data
 * @param data
 * @param httpExtra http请求的额外参数
 * @returns {*}
 */
let nId = 0
export function GetCustomBrandSellerDropData(data = {}, httpExtra = {}) {
  const requestId = httpExtra.requestId
  const isUnique = httpExtra?.isUnique ?? true
  const tempData = { ...data }
  if (isEmpty(tempData.level)) {
    tempData.level = 1
  }
  return $http3p({
    url: 'productCatalog/pimBrandCategoryForAdd',
    method: 'post',
    isUnique,
    ...isUnique ? {
      requestId: requestId || 'GetCustomBrandSellerDropData'
    } : {
      requestId: 'GetCustomBrandSellerDropData_' + nId++
    },
    data: {
      type: 1,
      source: getSource(),
      ...tempData,
      fromPage: window.location.href
    }
  })
}

/**
 * 获取统一后的Category下拉列表数据
 * 需要Asin Status筛选的，需要传data
 * @param data
 * @returns {*}
 */
export function GetCustomCategorySellerDropData(data = {}, httpExtra = {}) {
  const requestId = httpExtra.requestId
  const isUnique = httpExtra?.isUnique ?? true
  const tempData = { ...data }
  if (isEmpty(tempData.level)) {
    tempData.level = 1
  }
  return $http3p({
    url: 'productCatalog/pimBrandCategoryForAdd',
    method: 'post',
    isUnique,
    ...isUnique ? {
      requestId: requestId || 'GetCustomCategorySellerDropData'
    } : {
      requestId: 'GetCustomCategorySellerDropData_' + nId++
    },
    isUserRepeatCancel: true,
    data: {
      type: 2,
      source: getSource(),
      ...tempData,
      fromPage: window.location.href
    }
  })
}

/**
 * 用户勾选的利润计算公式中数据项
 */
export function GetFinancesFormulaCheckedApi() {
  return $http3p({
    url: 'profitability/financesFormulaChecked',
    method: 'post'
  })
}

/**
 * 获取Pacvue托管服务状态
 * failed 接口失败
 * yse 开启
 * no 没有开启
 */
export const isManagedByPacvue = ({ withCancel = true } = {}) => {
  const userStore = useUserStore()
  return $http1p({
    url: VITE_APP_ADMIN_MIDDLE + 'salesop/isManagedByPacvue',
    method: 'POST',
    data: {
      clientId: userStore?.user?.clientId,
      clientArea: VITE_APP_COUNTRY === 'US' ? 'COM' : VITE_APP_COUNTRY
    },
    isIgnoreRequestRegister: !withCancel
  })
}
export function GetMarketStoreMixinData() {
  return $http3p({
    url: '/accountManage/marketStore',
    method: 'GET',
    isUnique: true
  })
}

export const downloadHistory = (data, platform) => {
  return $httpCommon({
    url: 'downloadCenter/listDownloadLog',
    data,
    method: 'post',
    headers: {
      platform: platform || useCommonStore().commerceType.toUpperCase()
    }
  })
}

/**
 * 下载上传结果文件
 * @param {String} platform SELLER VENDOR
 * @returns
 */
export function downloadHistoryExcel(data, platform) {
  return downloadCommerceCommon('downloadCenter/downloadFile',
    data,
    '',
    {
      headers: { platform: platform || useCommonStore().commerceType.toUpperCase() },
      isUnique: true
    })
}

export const uploadFile = (url, commerceType, data, headers) => {
  const apiName = commerceType === 'Seller' ? $http3p : $http1p
  return apiName({
    url,
    method: 'post',
    data,
    isUserCatch: true,
    headers
  })
}
