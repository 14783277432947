<template>
  <div
    v-if="sharePage"
    id="HeadBar"
    class="flex flex-row w-full header-wrap items-center z-[1001]"
    :class="{
      '!bg-[var(--pac-theme-color)] is-special': isSpWhiteLabel && !spWhiteLabelConfig.topBarTheme,
      'fixed left-[62px] right-0 top-0 !w-auto': copilotDisplayMode === COPILOT_DISPLAY_MODE.FIXED_RIGHT
    }"
  >
    <ContactUs ref="contactUsDialogRef" />
    <IssueCollection ref="issueCollectionDialogRef" />
    <!-- Left Section Start -->
    <div class="flex-1">
      <div class="flex items-center">
        <!-- Platform Switch Start -->
        <!-- :popperOptions="{ modifiers: [{ name: 'offset', options: { offset: [pltLogoWidth / 2, 10] } }] }" -->
        <!-- 独立平台不出现平台跳转选择 -->
        <PacvuePopover
          v-model:visible="pltSwitchPopoverVisible"
          :disabled="config.SeparatePlatform || independentHosts.includes(hostName)"
          placement="bottom"
          :showArrow="false"
          popperClass="plt-switch-popover"
        >
          <template #reference>
            <div class="relative flex items-center mr-5 cursor-pointer">
              <span v-if="productLine === 'retailer'" class="text-[18px] font-semibold select-none" :class="themeColor === 'white' ? 'text-[#45464f]' : 'text-white'">{{ tabTitle }} HQ</span>
              <img v-else :src="`https://cdn-pacvue-public-doc.pacvue.com/oss/pacvue-cdn/vue3/${productLine}${themeColor === 'white' ? '' : '_white'}.svg`" alt="" />
              <el-icon
                v-if="!config.SeparatePlatform && !independentHosts.includes(hostName)"
                :size="16"
                class="ml-2 duration-500 transition-transform"
                :class="[pltSwitchPopoverVisible ? 'rotate-180' : 'rotate-0']"
              >
                <PacvueIconArrowDown1 />
              </el-icon>
            </div>
          </template>
          <PlatformSwitchPanel />
        </PacvuePopover>
        <!-- Platform Switch End -->

        <!-- Headquarter Insight Start -->
        <el-icon v-if="headquarterLoading" :size="24" class="animate-spin"><PacvueIconLoading /></el-icon>
        <PacvuePopover v-if="headquarterShow" v-model:visible="headquarterPopoverVisible" placement="bottom-start" :showArrow="false" popperClass="headquarter-popover">
          <template #reference>
            <div
              ref="headerHeadquarterEntryRef"
              class="flex items-center px-1.5 py-0.5 bg-[#fff] rounded-[28px] border-[3px] select-none cursor-pointer"
              :class="{ '!border-[1px] !border-[var(--pac-theme-color)]': themeColor === 'white' }"
            >
              <el-icon :size="16"><PacvueIconPacvueHq /></el-icon>
              <span class="ml-1.5 font-medium text-[12px] leading-5 text-[#66666c]">{{ `${tabTitle} HQ` }}</span>
              <div class="new-tag ml-1.5 px-1.5 bg-[#7367f0] rounded-2xl text-white font-medium text-[10px] leading-[18px]">{{ $t("New") }}</div>
            </div>
          </template>
          <HeadquarterSelectPanel :headquarterMenu="headquarterMenu" />
        </PacvuePopover>
        <!-- Headquarter Insight End -->

        <!-- Bol & Chewy to v1 Start -->
        <div
          v-if="availablePlatforms.includes(productLine) && ((productLine === 'bol' && bolVersion == 2) || (productLine === 'chewy' && chewyVersion == 2))"
          class="w-[150px] h-[36px] align-middle ml-4 rounded-md border-[1px] border-[#fff] text-center leading-[34px] cursor-pointer"
          :class="{ '!border-[var(--pac-theme-color)]': themeColor === 'white' }"
          @click="handleToV1Click"
        >
          <PacvueTooltip popper-class="!max-w-[250px]" effect="dark" content="You can click here to access historical data on the old site." placement="bottom">View Historical Data</PacvueTooltip>
        </div>
        <!-- Bol & Chewy to v1 End -->

        <!-- Version Tag Start -->
        <el-icon v-if="versionTag.includes('beta')" :size="48" class="ml-4">
          <PacvueIconBetaOrange v-if="themeColor === 'Mazarine'" />
          <PacvueIconBetaWhite v-else />
        </el-icon>
        <!-- Version Tag End -->
      </div>
    </div>
    <!-- Left Section End -->

    <!-- Right Section Start -->
    <!-- Copilot Start -->
    <PacvuePopover
      v-if="copilotShow && copilotShowExtra"
      v-model:visible="copilotPopoverShow"
      :disabled="copilotOpened"
      ref="copilotPopoverRef"
      placement="bottom"
      :width="copilotGuidingShow ? 376 : 245"
      :trigger="copilotGuidingShow ? 'click' : 'hover'"
    >
      <div v-if="!copilotGuidingShow">
        <div class="text-[16px] font-medium text-[#45464f]">{{ tabTitle }} Copilot</div>
        <div class="my-3 text-[#66666c]">{{ $t("Intelligent data analysis assistant for efficient work.") }}</div>
        <div class="w-full text-[var(--pac-theme-color)] text-right font-medium cursor-pointer" @click="deHandleCopilotOpen">{{ $t("Try Now") }} ></div>
      </div>
      <div v-else class="flex">
        <img class="w-[100px]" src="https://cdn-pacvue-public-doc.pacvue.com/oss/pacvue-cdn/vue3/CopilotModuleGuide.svg" alt="" />
        <div class="flex flex-col ml-3">
          <div class="mb-1 text-[16px] font-medium text-[#45464f]">{{ $t("amskey3255") }}</div>
          <div class="text-[#66666c]" :class="{ 'break-keep': locale === 'EN' }">{{ $t("amskey3256") }}</div>
          <div class="guiding-confirm-action mt-3 self-end">
            <span class="cursor-pointer" @click="handleCopilotGuidingReject">{{ $t("amskey3257") }}</span>
            <span class="ml-3 text-[var(--pac-theme-color)] cursor-pointer" @click="handleCopilotGuidingAccept">{{ $t("amskey3258") }}</span>
          </div>
        </div>
      </div>
      <template #reference>
        <el-icon v-if="!copilotRef" class="animate-spin" :size="24"><PacvueIconLoading /></el-icon>
        <img v-else src="https://cdn-pacvue-public-doc.pacvue.com/oss/pacvue-cdn/vue3/copilot-icon.png" class="w-7 cursor-pointer" @click="deHandleCopilotOpen" />
      </template>
    </PacvuePopover>
    <!-- Copilot End -->

    <!-- Chewy to New Start -->
    <div v-if="productLine === 'chewy' && chewyVersion != 2" class="flex items-center">
      <PacvuePopover placement="bottom" :width="300" trigger="click" :visible="isSwitchToNewVisible" :popperOptions="{ modifiers: [{ name: 'offset', options: { offset: [0, 3] } }] }">
        <div class="flex items-center">
          <span class="break-keep">{{ $t("Switch to the Chewy platform that uses the new API.") }}</span>
          <span class="text-[var(--pac-theme-color)] cursor-pointer" @click="handleSwitchToNewConfirm">{{ $t("OK") }}</span>
        </div>
        <template #reference>
          <img
            :src="`https://cdn-pacvue-public-doc.pacvue.com/oss/pacvue-cdn/vue3/switchNew${themeColor == 'white' ? 'FF9F43' : 'FFFFFF'}.svg`"
            alt=""
            class="relative mr-5 w-[124px] cursor-pointer"
            :class="{ 'top-[3px]': themeColor !== 'white' }"
            @click="handleSwitchToNewPlt"
          />
        </template>
      </PacvuePopover>
    </div>
    <!-- Chewy to New End -->

    <div v-if="!isInHeadquarter" class="flex items-center ml-5">
      <!-- Sync Start -->
      <PacvuePopover v-if="config.Synchronization && !isWhiteLabel" trigger="hover" @show="handleSyncPopoverShow" @hide="handleSyncPopoverHide" popper-class="sync-popover">
        <template #reference>
          <el-icon :size="22" class="mr-5 cursor-pointer">
            <PacvueIconSynchronization />
          </el-icon>
        </template>
        <div class="h-[56px] px-[24px] py-[14px] border-b-[1px] border-b-solid border-b-[#E9E9E9] flex flex-row justify-between">
          <span class="text-[16px] font-bold leading-[18px] self-center">{{ $t("amskey2853") }}</span>
          <div class="text-[var(--pac-theme-color)] text-[12px] leading-[18px] font-bold self-center cursor-pointer" @click="handleSyncViewAll">{{ $t("key1735") }}</div>
        </div>
        <el-scrollbar :max-height="274" wrap-class="w-full pt-2">
          <div v-pacvueLoading="syncInfoLoading">
            <div v-if="syncInfoArr.length > 0">
              <div v-for="info in syncInfoArr" class="h-[80px] pb-2 ml-6 w-[240px]">
                <div v-for="(ele, index) in info" class="h-6 pb-1 truncate" :class="index === 2 ? 'text-[#b2b2b2]' : 'text-[#666666]'">
                  <span :class="index === 0 ? 'text-[14px] text-[#45464F]' : 'text-[12px]'">{{ ele }}</span>
                </div>
              </div>
            </div>
            <div v-else class="flex h-[240px] w-[280px] justify-center pt-4">
              <el-empty :image-size="80" :description="$t('key491')" />
            </div>
          </div>
        </el-scrollbar>
      </PacvuePopover>
      <!-- Sync End -->
      <component :is="frameStore.customHeader" />
    </div>

    <div class="pl-[8px]" v-if="config.account && !isInHeadquarter">
      <PacvueSelect
        v-if="accountOptions?.length > 0"
        :data="accountOptions"
        v-model="accountValue"
        :dataCy="dataCy"
        :dataCyValue="dataCyValue"
        :dataCyFilter="dataCyFilter"
        :hasBorder="false"
        :props="props"
        :autoResize="true"
        :collapseTags="false"
        :minLimit="1"
        fitWidth
        minWidth="120px"
        dropdownWidth="15%"
        class="cursor-pointer"
        placeholder="Nothing Selected"
        @change="accountChange"
      />
    </div>
    <div
      v-if="!isInHeadquarter && popsWithMessage"
      class="flex border-l border-r border-[var(--el-color-border-head)] text-[20px] items-center justify-center pl-2 pr-2 ml-2"
      :class="{ '!border-l-0': !config.Synchronization && (!config.account || accountOptions.length === 0) && !isInHeadquarter }"
    >
      <div class="flex-1 justify-center items-center flex" v-if="config.Download">
        <CustomDownload @onClick="handleDownloadCenterClick()" :downloadingCount="downloadingCount"></CustomDownload>
      </div>
      <MessagePop v-if="config.Message" :class="{ 'ml-2.5': !!config.Download }" />
      <AnomalyAlert v-if="config.AnomalyAlert"></AnomalyAlert>
      <PacvuePopover trigger="hover" popper-class="help-center-popover" v-if="popMoreShow">
        <template #reference>
          <div class="text-[20px] justify-center items-center flex flex-1" :class="{ 'ml-2.5': !!config.Message }">
            <el-icon class="cursor-pointer" :size="22"><PacvueIconHelpCenter /></el-icon>
          </div>
        </template>
        <div class="flex flex-col py-[6px]">
          <div class="flex items-center py-2.5 px-3 cursor-pointer hover:bg-[var(--hover-color--)] transition-colors duration-100" @click="moveToCollage()" v-if="!isWhiteLabel && config.University">
            <div class="w-6">
              <el-icon class="cursor-pointer" :size="24"><PacvueIconPacvueCollage /></el-icon>
            </div>
            <div class="flex-1 pl-4">
              <div class="more-title">{{ $t("key1874") }}</div>
              <div class="more-text">{{ $t("key1875") }}</div>
            </div>
          </div>
          <div
            class="flex items-center py-2.5 px-3 cursor-pointer hover:bg-[var(--hover-color--)] transition-colors duration-100"
            @click="moveToTrainingPage()"
            v-if="!isSpecialWhiteLabel && config.Training"
          >
            <div class="w-6">
              <el-icon class="cursor-pointer flex-1" :size="24"><PacvueIconTraining /></el-icon>
            </div>
            <div class="flex-1 pl-4">
              <div class="more-title">{{ $t("key1876") }}</div>
              <div class="more-text">{{ $t("key1877") }}</div>
            </div>
          </div>
          <div
            class="flex items-center py-2.5 px-3 cursor-pointer hover:bg-[var(--hover-color--)] transition-colors duration-100"
            @click="handleKnowledgeBaseClick()"
            v-if="!isWhiteLabel && config.Knowledge"
          >
            <div class="w-6">
              <el-icon class="cursor-pointer flex-1" :size="24"><PacvueIconKnowledge /></el-icon>
            </div>
            <div class="flex-1 pl-4">
              <div class="more-title">{{ $t("key1878") }}</div>
              <div class="more-text">{{ $t("key1879") }}</div>
            </div>
          </div>
          <div
            class="flex items-center py-2.5 px-3 cursor-pointer hover:bg-[var(--hover-color--)] transition-colors duration-100"
            @click="handleContactUsClick()"
            v-if="!isWhiteLabel && config.Knowledge"
          >
            <div class="w-6">
              <el-icon class="flex-1" :size="24"><PacvueIconContactUs /></el-icon>
            </div>
            <div class="flex-1 pl-4">
              <div class="more-title">{{ $t("key2275") }}</div>
              <div class="more-text">{{ $t("key2276") }}</div>
            </div>
          </div>
          <div
            class="flex items-center py-2.5 px-3 cursor-pointer hover:bg-[var(--hover-color--)] transition-colors duration-100"
            @click="issueCollectionShow()"
            v-if="!isWhiteLabel && config.issueClientIds?.includes(userStore.user?.clientId)"
          >
            <div class="w-6">
              <el-icon class="flex-1" :size="24"><PacvueIconFeedback1 /></el-icon>
            </div>
            <div class="flex-1 pl-4">
              <div class="more-title">{{ $t("Feedback") }}</div>
              <div class="more-text">{{ $t("Report a problem") }}</div>
            </div>
          </div>
        </div>
      </PacvuePopover>
    </div>
    <div class="pl-5">
      <el-dropdown v-if="!isInHeadquarter && config.UserCenter" popper-class="header-right-dropdown" @visible-change="visibleChange" @command="handleTopRightDropItemClick" placement="top-start">
        <span class="el-dropdown-link flex items-center text-[var(--el-color-font-head)]">
          {{ userName }}
          <el-icon class="ml-1 rotate-0 transition-transform duration-200" :class="{ 'rotate-180': visible }" :size="20">
            <PacvueIconTopBarArrowDown />
          </el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item v-if="config.MyAccount">
              <RouterLink to="/Account/MyAccount" class="flex items-center w-full py-2 px-4">
                <el-icon class="!mr-4 px-[1px]" color="#333333" :size="20"><PacvueIconManage /></el-icon>
                <span class="text-[#333333]">{{ $t("key1882") }}</span>
              </RouterLink>
            </el-dropdown-item>
            <el-dropdown-item v-if="level !== 'User' && config.UserManage">
              <RouterLink to="/Permission/UserManage" class="flex items-center w-full py-2 px-4">
                <el-icon class="!mr-4" color="#333333" :size="20"><PacvueIconUserManagement /></el-icon>
                <span class="text-[#333333]">{{ $t("key1883") }}</span>
              </RouterLink>
            </el-dropdown-item>
            <el-dropdown-item v-if="config.BillingContact">
              <RouterLink to="/BillingContact/index" class="flex items-center w-full py-2 px-4">
                <el-icon class="!mr-4" color="#333333" :size="20"><PacvueIconBinding /></el-icon>
                <span class="text-[#333333]">Billing Contact</span>
              </RouterLink>
            </el-dropdown-item>
            <el-dropdown-item v-if="config.Balance">
              <RouterLink to="/Account/Balance" class="flex items-center w-full py-2 px-4">
                <el-icon class="!mr-4" color="#333333" :size="20"><PacvueIconBalance /></el-icon>
                <span class="text-[#333333]">My Balance</span>
              </RouterLink>
            </el-dropdown-item>
            <el-dropdown-item v-if="config.MyWallet">
              <RouterLink to="/Account/MyWallet" class="flex items-center w-full py-2 px-4">
                <el-icon class="!mr-4" color="#333333" :size="20"><PacvueIconBalance /></el-icon>
                <span class="text-[#333333]">My Wallet</span>
              </RouterLink>
            </el-dropdown-item>
            <el-dropdown-item v-if="config.Settings">
              <RouterLink to="/Account/Setting" class="flex items-center w-full py-2 px-4">
                <el-icon class="!mr-4" color="#333333" :size="20"><PacvueIconWidgetSetting /></el-icon>
                <span class="text-[#333333]">{{ $t("key1884") }}</span>
              </RouterLink>
            </el-dropdown-item>
            <el-dropdown-item v-if="config.Notification">
              <RouterLink to="/Notification" class="flex items-center w-full py-2 px-4">
                <el-icon class="!mr-4" color="#333333" :size="20"><PacvueIconNotification /></el-icon>
                <span class="text-[#333333]">{{ $t("key1885") }}</span>
              </RouterLink>
            </el-dropdown-item>
            <el-dropdown-item v-if="config.RRM && hasRRMPermission">
              <RouterLink to="/Account/RRM" class="flex items-center w-full py-2 px-4">
                <el-icon class="!mr-4" color="#333333" :size="20"><PacvueIconPriceTracker /></el-icon>
                <span class="text-[#333333]">{{ $t("key2914") }}</span>
              </RouterLink>
            </el-dropdown-item>
            <!-- Locale Toggle -->
            <el-dropdown-item v-if="config.Language && config.Language.length > 0" class="keep-default group relative">
              <img :src="langIconURL" class="!mr-[14px] w-[22px]" />
              <span class="text-[#333333]">{{ $t("key411") }}</span>
              <el-icon class="ml-6" :size="20"><PacvueIconArrowRight1 /></el-icon>
              <div class="absolute -left-[200px] top-0 hidden group-hover:!block w-[200px] py-1.5 px-0 bg-white shadow-[0_4px_14px_4px_rgba(0,0,0,.06)] rounded-md">
                <div
                  class="flex items-center h-[40px] pl-[15px] hover:bg-[var(--hover-color--)] transition-colors duration-100"
                  v-for="item in config.Language"
                  :key="item.id"
                  @click="handleLangToggle(item.id)"
                >
                  <img :src="item.url" class="w-[22px]" />
                  <span class="pl-3">{{ item.name }}</span>
                </div>
              </div>
            </el-dropdown-item>
            <!-- Logout -->
            <el-dropdown-item class="keep-default" command="logout" divided>{{ $t("key1886") }}</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <div v-else>{{ userName }}</div>
    </div>
    <!-- Right Section End -->
  </div>
  <div v-else class="header-wrap flex flex-row w-full items-center z-[1001]">
    <img v-if="!isWhiteLabel" class="w-[160px]" :src="`https://cdn-pacvue-public-doc.pacvue.com/oss/pacvue-cdn/vue3/pacvue+${productLine}white.svg`" alt="" />
    <img v-else class="w-[100px]" :src="`https://cdn-pacvue-public-doc.pacvue.com/oss/pacvue-cdn/vue3/${whiteLabelImg}.png`" alt="" />
  </div>
  <Copilot ref="copilotRef" v-model:copilotOpened="copilotOpened"></Copilot>
  <EmailVerifyDialog />
</template>

<script setup>
import { ref, computed, watch, watchEffect, defineAsyncComponent, inject, unref, onMounted, onUnmounted, nextTick } from "vue"
import { useRouter, useRoute } from "vue-router"
import { useCommonStore, useAppStore, useUserStore } from "@/store"
import useFrameStore from "../frameStore"
import { getPacvueUniversityUrl, getZendeskUrl, getProfile } from "@main/api/common"
import { formatTimeZone } from "@main/utils/common-util"
import { throttle } from "lodash-es"
import { useAutoDataCy, PacvueMessage } from "@pacvue/element-plus"
import { GetLastAMSSyncData, GetLastDSPSyncData } from "@main/api/syncData"
import { useUserInfo } from "@main/utils/common-util"
import { getMenus } from "../api"
import { getAllProfiles } from "@main/api/Advertising/profile"
import config from "@~/config.js"
import { storeToRefs } from "pinia"
import { useAnalyticsTrack } from "../analytics/index.js"
import CustomDownload from "./components/CustomDownload.vue"
import { useCustomDownload } from "./useCustomDownload"
import PlatformSwitchPanel from "./components/PlatformSwitchPanel.vue"
import HeadquarterSelectPanel from "./components/HeadquarterSelectPanel.vue"
import { isCN, isEU, isDev, isWhiteLabel, isSpWhiteLabel, judgeShare, spWhiteLabelConfig, tabTitle } from "../../constants/env"
import { independentHosts, headerTailoredConfig } from "../../config/white-label.js"
import { logoutProcessing } from "../../utils/logout"
import { hexToRgba } from "../../utils/special-logic.js"
import { DOMAIN_MAPPER_TEST, DOMAIN_MAPPER_PROD } from "../../constants/platform.js"
import { DISPLAY_MODE as COPILOT_DISPLAY_MODE, FRESHMAN_GUIDANCE_ACTION } from "../Copilot/constants"
import { judgeActiveCopilotGuidingPopByRoute } from "../../utils/copilot.js"
import { saveFreshmanGuidanceAction } from "../Copilot/api.js"
import { QUESTIONS_PRESETS_KB } from "../Copilot/config.js"

const userStore = useUserStore()
const frameStore = useFrameStore()

// 弹窗组件异步注册
let MessagePop = null
if (config.Message) {
  MessagePop = defineAsyncComponent(() => import("@Message/components/MessagePop.vue"))
}
let AnomalyAlert = null
if (config.AnomalyAlert) {
  AnomalyAlert = defineAsyncComponent(() => import("@Message/components/AnomalyIcon.vue"))
}
let ContactUs = null
if (config.Knowledge) {
  ContactUs = defineAsyncComponent(() => import("./components/ContactUsDialog.vue"))
}
let IssueCollection = null
if (config.issueClientIds?.includes(userStore.user?.clientId)) {
  IssueCollection = defineAsyncComponent(() => import("./components/IssueCollectionDialog.vue"))
}
let Copilot = null
if (config.Copilot && userStore.user?.copilotSwitch == 1) {
  Copilot = defineAsyncComponent(() => import("../Copilot/Copilot.vue"))
}
let EmailVerifyDialog = null
if (localStorage.getItem("EmailVerifyPrompt") == "true") {
  localStorage.removeItem("EmailVerifyPrompt")
  EmailVerifyDialog = defineAsyncComponent(() => import("../components/EmailVerifyDialog.vue"))
}

const DOMAIN_MAPPER = isDev ? DOMAIN_MAPPER_TEST : DOMAIN_MAPPER_PROD

/**
 * 是否有RRM Integration下拉菜单权限
 * @type {boolean}
 */
const hasRRMPermission = window.permissionObj?.rrmIntegration > 0

// 定义beta版本号以及主题色
// eslint-disable-next-line
const versionTag = ref(APP_MESSAGE ? APP_MESSAGE.version : "")
const { nextAutoDataId } = useAutoDataCy({ prefix: "auto_frame", prev: 1 })
const dataCy = nextAutoDataId()
const dataCyValue = nextAutoDataId()
const dataCyFilter = nextAutoDataId()

const productLine = window.productline || localStorage.getItem("productline")
const locale = window.locale || localStorage.getItem("locale")
const hostName = window.location.host

const headquarterLoading = ref(false)
const headquarterShow = ref(false)
const headquarterMenu = ref({})
const copilotDisplayMode = inject("copilotDisplayMode")
const availablePlatforms = computed(() => userStore?.user?.availablePlatforms || [])
const userId = computed(() => userStore?.user?.userId || "")
const pltSwitchPopoverVisible = ref(false)
const headquarterPopoverVisible = ref(false)
const { handleDataDownloaded, downloadingCount } = useCustomDownload()

const copilotRef = ref(null)
const copilotOpened = ref(false)
const contactUsDialogRef = ref(null)
const copilotPopoverRef = ref(null)
const headerHeadquarterEntryRef = ref(null)
const handleContactUsClick = function () {
  useAnalyticsTrack("Click Contact Us", { category: "Experience" })
  contactUsDialogRef.value?.show()
}
const props = ref({ value: "advertiserId", label: "amazonAccount" })
const whiteLabelImg = ref(window.location.hostname)
if (window.location.href.lastIndexOf("portal.revmo.com") != -1) {
  whiteLabelImg.value = window.location.hostname + "white"
}
const appStore = useAppStore()
const { account: accountOptions } = storeToRefs(appStore) //appStore.account

const accountValue = ref(appStore.chooseAccounts)
const commonStore = useCommonStore()
const bolVersion = computed(() => commonStore.bolVersion)
const chewyVersion = computed(() => commonStore.chewyVersion)

const userName = computed(() => userStore?.user?.userName)
const level = computed(() => userStore?.user?.level)
const themeColor = computed(() => spWhiteLabelConfig.topBarTheme || headerTailoredConfig?.topBarTheme || userStore?.userSettings?.themeColour)

if (userStore.user) {
  appStore.SET_HOMEURL(localStorage.getItem("storage_" + userStore.user.userName.toLowerCase() + "_homeUrl"))
} else {
  appStore.SET_HOMEURL("")
}

const copilotShow = computed(() => config.Copilot && userStore.user && userStore.user?.copilotSwitch == 1)
const copilotShowExtra = ref(true) // Copilot 开启的额外条件, 某些平台 (Commerce) 需按支持的路由模块按需开启
const copilotPopoverShow = ref(false)
const copilotGuidingShow = ref(false)
const copilotGuidingClicked = ref(false)
const isInHeadquarter = ["retailer"].includes(productLine)
const isSwitchToNewVisible = ref(false)

const route = useRoute()
const router = useRouter()

// 添加问题采集的弹窗方法
const issueCollectionDialogRef = ref(null)
const issueCollectionShow = () => {
  issueCollectionDialogRef.value?.show()
}

// Bol & Chewy 跳转至 V1 的 View Historical Data 处理
const handleToV1Click = () => {
  const pathPrefix = DOMAIN_MAPPER[productLine].pathPrefix
  window.open(`${window.location.protocol}//${window.location.hostname}/${pathPrefix}?crossNav=1&noCache=1&query=${productLine}&referrer=${window.location.origin}`, "_blank")
}

const judgeCopilotPopoverShow = () => {
  if (!localStorage.getItem(`storage_${userId.value}_CopilotGuided`)) {
    if (judgeActiveCopilotGuidingPopByRoute()) {
      copilotGuidingShow.value = true
      nextTick(() => {
        copilotPopoverShow.value = true
      })
    } else {
      copilotPopoverShow.value = false
      setTimeout(() => {
        copilotGuidingShow.value = false
      }, 300)
    }
  } else {
    copilotGuidingShow.value = false
  }
}

const handleCopilotGuidingReject = () => {
  localStorage.setItem(`storage_${userId.value}_CopilotGuided`, true)
  copilotPopoverShow.value = false
  copilotGuidingClicked.value = true
  setTimeout(() => {
    copilotGuidingShow.value = false
    copilotGuidingClicked.value = false
  }, 300)
  saveFreshmanGuidanceAction({ comment: FRESHMAN_GUIDANCE_ACTION.REJECT })
}

const handleCopilotGuidingAccept = () => {
  localStorage.setItem(`storage_${userId.value}_CopilotGuided`, true)
  copilotPopoverShow.value = false
  copilotGuidingClicked.value = true
  setTimeout(() => {
    copilotGuidingShow.value = false
    copilotGuidingClicked.value = false
  }, 300)
  // open copilot
  copilotRef.value?.show({ trigger: "guidance" })
  saveFreshmanGuidanceAction({ comment: FRESHMAN_GUIDANCE_ACTION.ACCEPT })
}

const unwatchRouteSideEffect = watch(
  () => [route.path, route.name],
  ([p, n]) => {
    // commerce copilot 根据模块判断是否出现 Icon 入口
    if (config.CopilotOnDemand) {
      let matched = false
      const moduleInfos = QUESTIONS_PRESETS_KB.value[productLine]?.keys() ?? []
      for (const moduleInfo of moduleInfos) {
        if (moduleInfo.path?.test(p) || n === moduleInfo.name) {
          matched = true
          break
        }
      }
      // 没有 1P 的账号也不能出现
      copilotShowExtra.value = matched && commonStore.channelList?.find?.((item) => item.id === "Vendor")
    }
    setTimeout(() => {
      if (config.Copilot && copilotRef.value) {
        judgeCopilotPopoverShow()
      }
    }, 500)
  },
  { immediate: true }
)

watchEffect(() => {
  if (config.Copilot && copilotRef.value) {
    setTimeout(() => {
      judgeCopilotPopoverShow()
      if (isCN && !localStorage.getItem("storage_copilotShow")) {
        copilotPopoverShow.value = true
        localStorage.setItem("storage_copilotShow", true)
      }
    }, 300)
  }
})

watch(copilotPopoverShow, (state) => {
  nextTick(() => {
    const copilotGuided = localStorage.getItem(`storage_${userId.value}_CopilotGuided`)
    if (!state && !copilotGuided && !copilotGuidingClicked.value && judgeActiveCopilotGuidingPopByRoute()) {
      copilotPopoverShow.value = true
      copilotGuidingShow.value = true
    }
  })
})

let sharePage = true
if (route.query.shareId) {
  sharePage = false
}

let visible = ref(false)
function visibleChange(val) {
  visible.value = val
}

const handleTopRightDropItemClick = async (command) => {
  switch (command) {
    case "logout":
      if (config.analytics) {
        // useAnalyticsTrack("Logout Success", { category: "Login" }, async () => {
        //   await logoutProcessing()
        // })
      }
      await logoutProcessing()
      break
  }
}

const accountChange = () => {
  appStore.SET_CHOOSEACCOUNTS(accountValue.value)
  sessionStorage.removeItem("profileList")
  window.location.reload()
}

const moveToTrainingPage = () => {
  if (window.location.href.lastIndexOf("seller360.io") != -1) {
    useAnalyticsTrack("Click Videos", { category: "Experience" }, () => {
      window.open("https://help.seller360.io/ArticleCategoriesDetails/pypfz4bmh17jY20MA3vX")
    })
  } else if (productLine === "dsp") {
    useAnalyticsTrack("Click Videos", { category: "Experience" }, () => {
      window.open(`${window.location.protocol}//${window.location.host}/Training`, "_blank")
    })
  } else {
    useAnalyticsTrack("Click Videos", { category: "Experience" })
    router.push("/Training")
  }
}

const moveToCollage = () => {
  if (userStore.user.mail) {
    getPacvueUniversityUrl({ userMail: userStore.user.mail }).then((res) => {
      useAnalyticsTrack("Click Pacvue University", { category: "Experience" }, () => {
        window.open(res)
      })
    })
  } else {
    useAnalyticsTrack("Click Pacvue University", { category: "Experience" })
    PacvueMessage({
      message: "Email is required to sign up Pacvue University, please input your email in My Account."
    })
  }
}

const handleKnowledgeBaseClick = () => {
  if (userStore.user.mail) {
    getZendeskUrl({ userMail: userStore.user.mail }).then((res) => {
      useAnalyticsTrack("Click Knowledge Base", { category: "Experience" }, () => {
        window.open(productLine.startsWith("commerce") ? `${res}/categories/12963682499613-Amazon-Commerce-User-Guide` : res)
      })
    })
  } else {
    useAnalyticsTrack("Click Knowledge Base", { category: "Experience" })
    PacvueMessage({
      message: "Email is required to go to Pacvue Feedback, please input your email in My Account."
    })
  }
}

// Synchronization处理
const syncInfoLoading = ref(false)
const syncInfoArr = ref([])
const handleSyncViewAll = () => {
  // goto View ALL page
  router.push({
    path: "/AsyncData"
  })
}

const handleSyncPopoverShow = () => {
  syncInfoLoading.value = true
  if (productLine === "amazon") {
    getAllProfiles({ userId: unref(useUserInfo().userId) }).then((data) => {
      if (data && data.length) {
        const profileIds = data.map((item) => item.id)
        GetLastAMSSyncData({ profileIds })
          .then((data) => {
            let newArray = []
            data.forEach((element) => {
              newArray.push([element.showName, element.ActionName + " " + element.SyncType, element.FinishTimeShow + " (" + formatTimeZone(element.timezone) + ")"])
            })
            syncInfoArr.value = newArray
            syncInfoLoading.value = false
          })
          .catch((err) => {
            console.log(err)
            syncInfoArr.value = []
            syncInfoLoading.value = false
          })
      } else {
        syncInfoLoading.value = false
      }
    })
  } else if (productLine === "dsp") {
    getProfile({ profileIds: [], countryList: [] }).then((res) => {
      if (res.data && res.data.length) {
        const ids = res.data.map((item) => item.profileId)
        GetLastDSPSyncData({ profileIds: ids })
          .then((data) => {
            let newArray = []
            data.forEach((element) => {
              var name = (element.performanceType || element.type).charAt(0) + (element.performanceType || element.type).slice(1).toLowerCase()
              newArray.push([
                element.showName,
                name + "-" + (element.performanceType ? "Performance" : name == "Creative" ? "List" : "Setting"),
                element.AddTimeShow + "" + formatTimeZone(element.timezone)
              ])
            })
            syncInfoArr.value = newArray
            syncInfoLoading.value = false
          })
          .catch((err) => {
            syncInfoArr.value = []
            syncInfoLoading.value = false
          })
      } else {
        syncInfoLoading.value = false
      }
    })
  }
}
const handleSyncPopoverHide = () => {
  syncInfoArr.value = []
  syncInfoLoading.value = false
}

const isSpecialWhiteLabel = !isWhiteLabel || window.location.href.indexOf("seller360.io") != -1 ? false : true
const popMoreShow = ref(true)
if (
  !(isWhiteLabel == false && config.University) &&
  !(isSpecialWhiteLabel == false && config.Training) &&
  !(isWhiteLabel == false && config.Feedback) &&
  !(isWhiteLabel == false && config.Knowledge)
) {
  popMoreShow.value = false
}
const popsWithMessage = ref(true)
if (!config["Message"] && !popMoreShow.value) {
  popsWithMessage.value = false
}
const handleDownloadCenterClick = function () {
  const { href } = router.resolve({ path: "/Download" })
  window.open(href, "_blank")
}
const handleCopilotOpen = () => {
  // 引导开启时, 必须点击 Let's Go 才能开启 Copilot, 关闭所有其他入口
  if (copilotGuidingShow.value) {
    return
  }
  copilotRef.value?.show()
}

const deHandleCopilotOpen = throttle(handleCopilotOpen, 1500, { trailing: false })

const handleSwitchToNewPlt = () => {
  const pathPrefix = DOMAIN_MAPPER["chewyv2"].pathPrefix
  window.open(`${window.location.protocol}//${window.location.hostname}/${pathPrefix}?crossNav=1&noCache=1&query=chewy&referrer=${window.location.origin}`, "_blank")
}

const handleSwitchToNewConfirm = () => {
  isSwitchToNewVisible.value = false
  localStorage.setItem(`_per_store_${userId.value}_SwitchToNewConfirmed`, true)
}

const lang = ref(localStorage.getItem("locale"))
const langIconURL = ref("")
if (config.Language) {
  config.Language.some((item) => {
    if (item.id === lang.value) {
      langIconURL.value = item.url
      return true
    }
  })
}

const handleLangToggle = (lang) => {
  if (sessionStorage.getItem("setting")) {
    let setting = JSON.parse(sessionStorage.getItem("setting"))
    setting.language = lang
    sessionStorage.setItem("setting", JSON.stringify(setting))
    sessionStorage.removeItem("Menu")
  }
  localStorage.setItem("locale", lang)
  if (window.location.href.indexOf("?") !== -1) {
    window.location.href = window.location.href + "&Language=" + lang
  } else {
    window.location.href = window.location.href + "?Language=" + lang
  }
}

const getHeadquarterMenuPermission = async () => {
  const preliminaryCondition = !judgeShare() && config.retailer && availablePlatforms.value.includes("retailer") && !isInHeadquarter && (!isWhiteLabel || window.location.hostname.includes("groupm"))
  // 是否请求 HQ 菜单的前置条件, 若不满足, 则无需继续
  if (!preliminaryCondition) {
    return
  }
  headquarterLoading.value = true
  try {
    let menuTree = []
    // 若 HQ 菜单存在缓存, 且调用时机不是国际化切换后, 则直接使用缓存
    if (sessionStorage.getItem("Menu-HQ") && !window.isUseHeader) {
      menuTree = JSON.parse(sessionStorage.getItem("Menu-HQ"))
    } else {
      // HQ 菜单国际化的确定，必须使用请求头中的 Language 字段
      menuTree = await getMenus({ isUseHeader: true }, "retailer")
      sessionStorage.setItem("Menu-HQ", JSON.stringify(menuTree))
    }
    let permissionVal = 0
    const recursiveFn = (treeNode) => {
      for (const menuItem of treeNode) {
        if (menuItem.children?.length > 0) {
          recursiveFn(menuItem.children)
        }
        permissionVal += menuItem.permissionType
      }
    }
    recursiveFn(menuTree)
    headquarterShow.value = preliminaryCondition && permissionVal > 0
    headquarterMenu.value = menuTree
    nextTick(() => {
      const appEl = document.querySelector("#app")
      const headerBgColor = window.getComputedStyle(appEl).getPropertyValue("--el-color-bg-head")
      headerHeadquarterEntryRef.value?.style.setProperty("border-color", hexToRgba(headerBgColor, 0.7))
    })
  } catch (err) {
    // PASS
  } finally {
    headquarterLoading.value = false
  }
}

onMounted(() => {
  getHeadquarterMenuPermission()
  // Chewy v1 跳转 v2 的提示开启
  setTimeout(() => {
    if (productLine === "chewy" && chewyVersion.value != 2 && !localStorage.getItem(`_per_store_${userId.value}_SwitchToNewConfirmed`)) {
      isSwitchToNewVisible.value = true
    }
  }, 300)
})

onUnmounted(() => {
  unwatchRouteSideEffect()
})
</script>

<style lang="scss" scoped>
.header-wrap {
  height: 56px;
  padding: 0 24px;
  font-size: 14px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  line-height: 14px;
  background-color: var(--el-color-bg-head);
  color: var(--el-color-font-head);
}
.is-special {
  :deep(.el-input__wrapper) {
    background: var(--pac-theme-color) !important;
  }
}
:deep(.el-input__wrapper) {
  background: var(--el-color-bg-head);
  border: none;
  border-radius: 0;
  box-shadow: none;
  color: var(--el-color-font-head);
  &:focus {
    border: none;
    box-shadow: none;
  }
  &:hover {
    border: none;
    box-shadow: none;
  }
  .el-input__inner {
    background: none;
    outline: none;
    border: none;
  }
  input::placeholder {
    color: var(--el-color-font-head);
  }
  .el-input__prefix {
    .el-input__prefix-inner {
      span {
        color: var(--el-color-font-head) !important;
      }
    }
  }
  .el-input__suffix {
    .el-input__suffix-inner {
      .el-icon {
        color: var(--el-color-font-head);
      }
    }
  }
}

.more-title {
  color: #45464f;
  line-height: 20px;
  font-weight: 600;
}
.more-text {
  color: #66666c;
  line-height: 20px;
}
</style>

<style lang="scss">
.plt-switch-popover {
  width: 256px !important;
  min-width: none !important;
  padding: 0 !important;
  border-radius: 6px !important;
  left: 62px + 24px !important;
}

.headquarter-popover {
  top: 50px !important;
  width: fit-content !important;
  min-width: unset !important;
  max-width: unset !important;
  padding: 0 !important;
  border-radius: 6px !important;
}

.el-dropdown__popper {
  :deep(.el-scrollbar) {
    overflow: inherit !important;
  }
}

.el-dropdown-menu__item {
  padding: 8px 16px !important;
}

.sync-popover {
  padding: 0 !important;
  width: 290px !important;
  height: 330px !important;
}

.help-center-popover {
  padding: 0 !important;
  min-width: none !important;
}

.header-right-dropdown {
  .el-scrollbar {
    overflow: initial;
    .el-scrollbar__wrap {
      overflow: initial;
    }
  }
  .is-vertical {
    display: none !important;
  }
  .el-dropdown-menu__item:not(.keep-default) {
    padding: 0 !important;
  }
}
</style>
