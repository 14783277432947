import CurrencySymbol from '@commerceCommon/const/CurrencySymbol'
import { getSettings, getCommerceSettings } from '@!/api/Account/settings'
import { GetAdSpApiNotInCommerceCountApi } from '@!/api/Account/Authorization.js'
import { GetUserInfoApi } from '@!/api/Account/user.js'
import { app, InitApp } from '@pacvue/frame'
import { initwebSocket } from '@pacvue/frame/websocket'
import { getIsTopTen } from './util'

const state = () => {
  return {
    user: null,
    userSettings: null,
    sharePage: true,
    spApiNotInCommerceCount: 0
  }
}

const newVersionSalesImpactAnalysisGroupMClientIdList = [
  // US
  62, 106, 318, 515, 551, 611, 623, 623, 684, 707,
  711, 728, 728, 744, 790, 838, 857, 866, 877, 878,
  914, 938, 955, 971, 984, 1004, 1030, 1042, 1046, 1103,
  1135, 1167, 1174, 1182, 1247, 1350, 1387, 1404, 1428, 1474,
  1634, 1800, 1848, 2007, 2095, 2210, 2313, 2527, 2568, 2571,
  2749, 2954, 2995, 3140, 3345, 3358, 3380, 3433, 3472, 3474,
  3484, 3623, 3624, 3625, 3642, 3927, 3940,
  // EU
  200035, 200044, 200059, 210062, 210184, 210343, 210454, 210492, 210549, 210556,
  210558, 210581, 210596, 210598
]

const getters = {
  isDemo(state) {
    return [432, 3438].includes(state.user?.clientId) || state.user?.userName?.toLowerCase() === 'Demo@pacvue.com'.toLowerCase() ||
    state.user?.userName?.toLowerCase() === 'Pacvue Demo'.toLowerCase()
  },
  isTenLevel(state) {
    return getIsTopTen(state.user?.clientId)
  },
  // 项目中已不使用这个变量
  isGroupM(state) {
    return [62, 515, 210454].includes(state.user?.clientId)
  },
  // hasSalesImpactAnalysis(state) {
  //   return [
  //     ...newVersionSalesImpactAnalysisGroupMClientIdList,
  //     // US 非groupM
  //     2523
  //   ].includes(state.user?.clientId)
  // },
  isNewVersionGroupM(state) {
    return [
      ...newVersionSalesImpactAnalysisGroupMClientIdList
    ].includes(state.user?.clientId)
  },
  defaultCountryCode(state) {
    return state.userSettings?.defaultCurrency || 'US'
  },
  defaultMoneySymbol(state) {
    return CurrencySymbol[state.defaultCountryCode] || '$'
  },
  isTestAdminUser(state) {
    return state.user?.clientId === 62
  },
  // 是否展示Content Authority
  isShowContentAuthority(state) {
    return !!state.userSettings?.featureList?.includes('ContentAuthority')
  },
  locale(state) {
    const userSettings = state.userSettings
    if (userSettings) {
      return userSettings.language || 'EN'
    }
    if (window.location.href.includes('.cn')) {
      return 'ZH'
    }
    return 'EN'
  }
}

function firstGetUserInfo(data) {
  if (localStorage.getItem('SignFlag') === 'true') {
    app.config.globalProperties.$eventHub.emit('getUserInfo', data)
  }
}

const actions = {
  logout() {
    if (window.commerceWebSocket) {
      window.commerceWebSocket.close(1000, 'client close')
    }
    if (this.user) {
      this.user.userName = null
    }
  },
  async userAction() {
    let userInfo = JSON.parse(sessionStorage.getItem('useInfo') || '{}')
    if (!userInfo.userName) {
      userInfo = await GetUserInfoApi()
      firstGetUserInfo(userInfo)
      initwebSocket()
    }
    sessionStorage.setItem('useInfo', JSON.stringify(userInfo))
    localStorage.setItem('uid', userInfo.userId)
    this.SET_USER(userInfo)
    window.socketErrCount = 0
    return userInfo
  },
  async userSetting() {
    let setting = {}
    if (sessionStorage.getItem('setting')) {
      setting = JSON.parse(sessionStorage.getItem('setting'))
    } else {
      const results = await Promise.all([getSettings(), getCommerceSettings()])
      setting = results[0]
      setting.asinStatus = !!setting.asinStatusSwitch
      setting.commerceSettings = results[1]
      setting.language = localStorage.getItem('locale') || 'EN'
    }
    if (setting.themeColour === 'white') {
      document.getElementsByTagName('body')[0].setAttribute('class', 'theme1')
    } else if (setting.themeColour === 'black') {
      document.getElementsByTagName('body')[0].setAttribute('class', 'theme2 borderversion')
    } else {
      document.getElementsByTagName('body')[0].setAttribute('class', 'theme2')
    }
    this.SET_USERSETTINGS(setting)
    window.HomePageId = setting?.homepageMenuId ?? -1
  },
  SET_USER(user) {
    if (this.userSettings) {
      user.defaultCurrency = this.userSettings.defaultCurrency
    }
    this.user = user
  },
  SET_USERSETTINGS(setting) {
    // 组件日期格式化是从session种获取的
    setting.dateFormat = '0'
    this.userSettings = setting
    window.perSetting = setting
    if (setting.defaultCurrency && this.user) {
      this.user.defaultCurrency = setting.defaultCurrency
    }
    sessionStorage.setItem('setting', JSON.stringify(setting))
  },
  SET_USERCURRENCY(currency) {
    this.userSettings.defaultCurrency = currency
    this.user.defaultCurrency = currency
    sessionStorage.setItem('setting', JSON.stringify(this.userSettings))
  },
  setSpApiNotInCommerceCount(count) {
    this.spApiNotInCommerceCount = count
  },
  getSpApiNotInCommerceCount() {
    return new Promise((resolve) => {
      GetAdSpApiNotInCommerceCountApi()
        .then((data) => {
          this.setSpApiNotInCommerceCount(data)
          resolve(data)
        })
        .catch(() => {
          resolve(0)
        })
    })
  }
}

export default {
  state,
  getters,
  actions
}
